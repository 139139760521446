$(document).foundation();


$(document).ready(function(){
    $("#navigation").navigation({
    mobileBreakpoint: 1160
    });
});


$("#navigation").fixed({
    topSpace: 0
});

 
$(window).scroll(function() {
    if ($(this).scrollTop()>560)
     {
        $('.bashful').removeClass('hide-for-large');		
     }
    else
     {
        $('.bashful').addClass('hide-for-large');
     }
 });


//COLORBOX MODALS
$(".inline-modal").colorbox({inline:true});
$(".ajax").colorbox();
$(".iframe").colorbox({iframe:true, width:"99%", height:"99%"});

